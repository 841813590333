import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Image from 'gatsby-image';
import SEO from 'components/seo';
import { Section, Container, Aside, Col33 } from 'components/misc';

const FlexContainer = styled(Container)`
  display: flex;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    flex-direction: column;
  }
`;

const Activities = styled.div`
  width: 75%;
  display: flex;
  flex-wrap: wrap;

  @media(max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    width: 100%;
  }
`;

const StyledActivity = styled(Col33)`
  margin-bottom: 30px;
  transition: all .2s ease-in-out;
  opacity: 1;

  :hover {
    cursor: pointer;
    transform: scale(1.01);
    opacity: .9;
  }
`;

const Name = styled.h2`
  font-size: 1.285rem;
  font-weight: 600;
  margin-bottom: 8px;
`;

const ImageWrapper = styled.div`
  width: 100%;
`;

const StyledImage = styled(Image)`
  width: 100%;
  object-fit: cover;
`;

const ShortcutList = styled.ul`
  @media(max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    display: none;
  }
`;

const KeyActivities = ({ data: { activities: { nodes }}})=> {
  useEffect(()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <SEO title="Kluczowe działalności" />
      <Section topSection>
        <FlexContainer>
          <Aside title="Kluczowe działalności" withTitleAndShortcut>
            <ShortcutList>
              {nodes.map((activity)=> <li key={activity.name}>
                <a href={activity.link} target="_blank" rel="noopener noreferrer">{activity.name}</a>
              </li>)}
            </ShortcutList>
          </Aside>
          <Activities>
            {nodes.map((activity)=> <StyledActivity key={activity.name}>
              <a href={activity.link} target="_blank" rel="noopener noreferrer">
                <Name>{activity.name}</Name>
                <ImageWrapper>
                  <StyledImage fluid={activity.image.fluid} alt={activity.image.alt} />
                </ImageWrapper>
              </a>
            </StyledActivity>)}
          </Activities>
        </FlexContainer>
      </Section>
    </>
  );
};

export const query = graphql`{
  activities: allDatoCmsActivity(sort: {fields: orderNumber, order: ASC}, filter: {locale: {eq: "pl"}}) {
    nodes {
      name
      link
      image {
        alt
        fluid(maxWidth: 360) {
          ...GatsbyDatoCmsFluid_tracedSVG
        }
      }
    }
  }
}`;

export default KeyActivities;
